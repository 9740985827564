html,
body {
    padding: 0;
    margin : 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
        Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
        sans-serif;
}

* {
    box-sizing: border-box;
}

footer {
    width          : 100%;
    height         : 100px;
    border-top     : 1px solid #eaeaea;
    display        : flex;
    justify-content: center;
    align-items    : center;
}

footer img {
    margin-left: 0.5rem;
}

footer a {
    display        : flex;
    justify-content: center;
    align-items    : center;
}

a {
    color          : inherit;
    text-decoration: none;
}

.logo {
    height: 1em;
}

@media (max-width: 600px) {
    .grid {
        width         : 100%;
        flex-direction: column;
    }
}

.dark-mode {
    background-color: #212121;
    color           : rgb(241, 241, 241);
}

.dark-mode footer {
    border-top: 1px solid #0c0c0c;
}