.wheel {
    border-collapse: collapse;
    display        : inline-block;
    margin         : 0 auto;
}

.wheel td {
    border : 2px solid rgb(0, 0, 0);
    padding: 5px;
}

.payline {
    border-collapse: collapse;
    display        : inline-block;
    margin         : 0 auto;
}

.payline td {
    width: 10px;
    height: 10px;
    border : 1px solid rgb(0, 0, 0);
}

.payline td.active {
    background-color: red;
}

.winning-line {
    border-bottom: rgb(0, 0, 0) solid 1px;
    margin: 2px 5px 10px 5px;
    padding: 2px;
}