.wheel {
    border-collapse: collapse;
    display        : inline-block;
    margin         : 0 auto;
}

.wheel td {
    border : 2px solid rgb(0, 0, 0);
    padding: 5px;
}

.wheel td.active {
    background-color: rgb(153, 153, 153);
}

.payline {
    border-collapse: collapse;
    display        : inline-block;
    margin         : 0 5px;
}

.payline td {
    width : 10px;
    height: 10px;
    border: 1px solid rgb(0, 0, 0);
}

.dark-mode .payline td {
    border: 1px solid rgb(255, 255, 255);
}

.payline td.active {
    background-color: red;
}

.winning-line {
    border-bottom: rgb(0, 0, 0) solid 1px;
    margin       : 2px 5px 10px 5px;
    padding      : 2px;
}

.dark-mode .winning-line {
    border-bottom: rgb(255, 255, 255) solid 1px;
}

.wheel-spawned {
    border-bottom: none;
    width        : fit-content;
}

.wheel-spawned ul:first-child {
    border-left: 2px solid rgb(153, 153, 153);
}

.wheel-spawned::after {
    clear  : both;
    content: "";
    display: table;
}

.wheel-spawned ul {
    margin        : 0;
    padding       : 0;
    display       : table-cell;
    vertical-align: bottom;
    border-top    : 2px solid transparent;
    border-right  : 2px solid rgb(153, 153, 153);
}

.wheel-spawned li {
    list-style: none;
    padding   : 2px;
    width     : 60px;
}

.dark-mode .wheel td {
    border-color: white;
}